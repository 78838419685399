export const ApiConstant = {
  BASE_URL: process.env.REACT_APP_API_ENDPOINT,
  GET_ALL_MODULES: "/role/getAllModules",
  ROLE_LISTING: "/role/getAllRoles",
  CREATE_ROLE: "/role/",
  EMPLOYER_LISTING: "/employer/getAllEmployers",
  ACTIVE_EMPLOYER_LISTING: "/employer/getAllActiveEmployer",
  CREATE_EMPLOYER: "/employer/",
  SUBMIT_EMPLOYER: "/employer/complete",
  EMPLOYER_BY_ID: "/employer/getById",
  CHANGE_EMPLOYER_STATUS: "/employer/changeStatus",
  DELETE_EMPLOYER: (employerID: string) => `/employer/${employerID}`,
  ORG_EMPLOYEE_LISTING: "admin/user/getAllOrgFile",
  DOWNLOAD_EMPLOYEE_FILE: `employee/downloadEmployerBulkFile`,

  //roles
  ROLE_BY_ID: "/role/getById",
  GET_ACTIVE_ROLES: "/role/getActiveRoles",
  CHANGE_ROLE_STATUS: "/role/changeStatus",
  DELETE_ROLE: (roleID: string) => `/role/${roleID}`,

  //admin users
  CREATE_ADMIN: "/admin/user",
  ADMIN_LISTING: "/admin/user/getAllAdminUsers",
  ACTIVE_ADMIN_LISTING: "/admin/user/getAllActiveAdmin",
  ADMIN_BY_ID: "/admin/user/getById",
  CHANGE_ADMIN_STATUS: "/admin/user/changeStatus",
  DELETE_ADMIN_USER: (adminID: string) => `/admin/user/${adminID}`,

  //employee
  EMPLOYEE_LISTING: "/employee/getAllEmployees",
  EMPLOYEE_BY_ID: "/employee/getById",
  ADD_ROLE_EMPLOYEE: "/employee/employeeImport",
  UNBLOCK_USER: "/employee/unblockUser",
  ENABLE__DISABLE_USER_MFA: "/employee/toggleUserMFA",
  ADD_BULK_IMPORT_NOTIFY: "/employee/employeeImport",
  MANAGE_ROLE_EMPLOYEE: "/employee/updateEmployeeRoles",
  CHANGE_EMPLOYEE_STATUS: "/employee/changeStatus",
  UPDATE_ROLE_EMPLOYEE: "/employee/updateEmployeeDetails",
  DOWNLOAD_ERROR_FILE: "/employee/downloadErrorFile",
  DOWNLOAD_SAMPLE_FILE: "/employee/downloadSampleEmployeeImportFile",
  ALL_NOTIFICATIONS: "/employee/getAllBulkImportFiles",
  GET_PENSION_FUNDS_LIST: "masterEntity/pensionFunds/getPensionFundsByEmployee",
  GET_RESET_PASSWORD_LINK: "employee/employeePasswordReset",

  //productMaster
  PRODUCT_MASTER_LISTING: "/product/getAllProducts",
  PRODUCT_ACTIVE_MASTER_LISTING: "/product/getAllActiveProducts",
  PRODUCT_MASTER_BY_ID: (Id: string) => `/product/getById/${Id}`,
  EDIT_PRODUCT_MASTER_BY_ID: `/product/`,

  //masterEntity
  SCHEME_TYPES: "/masterEntity/schemes/getAllScheme",
  SCHEME_BY_ID: (schemeId: string) =>
    `/masterEntity/schemes/getSchemeById/${schemeId}`,
  SCHEME_BY_EMPLOYER_ID: (empId: string) =>
    `/masterEntity/employerScheme/schemeTypeForEmployer/${empId}`,
  ADD_COMMENT: "/masterEntity/addComment",
  CHANGE_SCHEME_TYPE_STATUS: "/masterEntity/schemes/changeStatus",
  PROVIDERS: "/masterEntity/provider/providerGetAll",
  CREATE_PROVIDER: "/masterEntity/provider/providerCreate",
  PROVIDER_BY_ID: (providerId: string) =>
    `/masterEntity/provider/getProviderById/${providerId}`,
  GET_ALL_ACTIVE_SCHEME: "/masterEntity/schemes/getAllActiveSchemeTypes",
  UPDATE_PROVIDER: (providerId: string) =>
    `/masterEntity/provider/update/${providerId}`,
  CHANGE_PROVIDER_STATUS: "/masterEntity/provider/changeStatus",
  INVESTMENT_OPTIONS: "/masterEntity/investmentOptions/GetAll",
  INVESTMENT_OPTION_BY_ID: (investmentId: string) =>
    `/masterEntity/investmentOptions/getById/${investmentId}`,
  UPDATE_INVESTMENT_OPTION: (investmentId: string) =>
    `/masterEntity/investmentOptions/update/${investmentId}`,
  CREATE_INVESTMENT_OPTION: "/masterEntity/investmentOptions/create",
  GET_ALL_ACTIVE_PROVIDER: "/masterEntity/provider/getAllActiveProvider",
  CHANGE_INVESTMENT_OPTIONS_STATUS:
    "/masterEntity/investmentOptions/changeStatus",

  PENSION_FUNDS: "/masterEntity/pensionFunds/GetAll",
  PENSION_FUND_BY_ID: (id: string) =>
    `/masterEntity/pensionFunds/getById/${id}`,
  CREATE_PENSION_FUND: "/masterEntity/pensionFunds/Create",
  UPDATE_PENSION_FUND: (id: string) =>
    `/masterEntity/pensionFunds/update/${id}`,
  CHANGE_PENSION_FUND_STATUS: "/masterEntity/pensionFunds/changeStatus",
  GET_ALL_ACTIVE_INVESTMENT_OPTIONS:
    "/masterEntity/investmentOptions/getAllActiveInvestmentOptions",

  PENSION_TYPE: "masterEntity/pensionFundTypes/getAll",
  PENSION_TYPE_BY_ID: (id: string) =>
    `/masterEntity/pensionFundTypes/getById/${id}`,
  CREATE_PENSION_TYPE: "/masterEntity/pensionFundTypes/Create",
  UPDATE_PENSION_TYPE: (id: string) =>
    `/masterEntity/pensionFundTypes/update/${id}`,
  GET_ALL_ACTIVE_PENSION_FUNDS:
    "/masterEntity/pensionFunds/getAllActivePensionFunds",
  DOWNLOAD_MASTER_ENTITY_FILE: (type: string) =>
    `/masterEntity/masterDataEntity/downloadCsv?folderName=${type}`,
  AVC_PLANS: "masterEntity/plan/getAllPlans",
  AVC_PLANS_BY_ID: (id: string) => `/masterEntity/plan/${id}`,
  AVC_PLANS_PAYROLL_IDENTIFIER: "/masterEntity/payPeriod/getActivePayPeriods",

  ACCESS_MODULES: "/admin/user/accessModules",

  //Supplier
  GET_ALL_SUPPLIERS: "/masterEntity/supplier/supplierGetAll",
  SUPPLIER_BY_ID: (id: string) =>
    `/masterEntity/supplier/supplierGetById/${id}`,
  CREATE_SUPPLIER: "/masterEntity/supplier/supplierCreate",
  UPDATE_SUPPLIER: (id: string) =>
    `/masterEntity/supplier/supplierUpdate/${id}`,
  ADD_SUPPLIER_COMMENT: "/masterEntity/schemes/addComment",
  CHANGE_SUPPLIER_STATUS: "/masterEntity/supplier/changeStatus",

  SCHEME_LIST: "/masterEntity/employerScheme/getAllSchemes",
  CREATE_SCHEME: "/masterEntity/employerScheme",
  UPDATE_SCHEME: (id: string) =>
    `/masterEntity/employerScheme/updateSchemeById/${id}`,
  SCHEME_DETAIL_BY_ID: (id: string) =>
    `/masterEntity/employerScheme/getSchemeById/${id}`,
  GET_ACTIVE_PENSION_TYPES:
    "/masterEntity/pensionFundTypes/getAllActivePensionTypes",
  GET_ALL_SCHEME_TYPES: "/masterEntity/schemes/getAllActiveSchemeTypes",
  GET_ACTIVE_EMPLOYER_LIST: "/employer/getAllActiveEmployer",
  CHANGE_SCHEME_STATUS: (id: string) =>
    `/masterEntity/employerScheme/changeStatus/${id}`,

  // PayPeriod endpoints
  CREATE_PAY_PERIOD: "/masterEntity/payPeriod",
  PAY_PERIOD_BY_ID: (id: string) =>
    `/masterEntity/payPeriod/getPayPeriodById/${id}`,
  GET_ALL_PAY_PERIOD: "/masterEntity/payPeriod/getAllPayPeriods",
  UPDATE_PAY_PERIOD: (id: string) =>
    `/masterEntity/payPeriod/updatePayPeriodById/${id}`,
  CHANGE_PAY_PERIOD_STATUS: (id: string) =>
    `/masterEntity/payPeriod/changeStatus/${id}`,

  // changelog
  CHANGE_LOG_LIST: "/masterEntity/changeLog/getAllLogs ",
  CHANGE_LOG_BY_ID: (id: string) =>
    `/masterEntity/changeLog/getChangeLogsByID/${id}`,

  //ims-webminar
  CREATE_IMS_WEBINAR: `/webinarims/webinarCreate`,
  GET_EMPLOYER_BY_QUERY: `/employer/getEmployerByLocationAndProduct`,
  IMS_WEBINAR_LISTING: "/webinarims/getAllWebinar",
  WEBINAR_BY_ID: "/webinarims/getWebinarById",
  DUPLICATE_WEBINAR_BY_ID: "/webinarims/createDuplicateWebinar",
  UPDATE_IMS_WEBINAR: (id: string) => `/webinarims/webinarUpdate/${id}`,
  ADD_ATTENDEES: `/webinarims/attendeeCreate`,
  CHANGE_WEBINAR_STATUS: "/webinarims/webinarChangeStatus",
  GET_ATTENDEES_LISTING: "/webinarims/attendeeGetByWebinar",
  CANCEL_WEBINAR: "/webinarims/attendeeUpdate",
  DOWNLOAD_ATTENDEES:
    "/webinarims/webinarims-attendee/downloadCsv?folderName=WebinarAttendeeDetails",

  //seminar
  CREATE_SEMINAR: `/seminar/seminarCreate`,
  SEMINAR_LISTING: "/seminar/getAllSeminar",
  SEMINAR_BY_ID: "/seminar/getSeminarById",
  UPDATE_SEMINAR: (id: string) => `/seminar/seminarUpdate/${id}`,
  ADD_ATTENDEES_SEMINAR: `/seminar/attendeeCreate`,
  GET_ATTENDEES_SEMINAR_LISTING: "/seminar/attendeeGetBySeminar",
  CANCEL_SEMINAR: "/seminar/attendeeUpdate",

  //cms-pages
  CMS_LISTING: "/webinarims/cmsPages/cmsPagesGetAll",

  // htc-config >> journey
  GET_ALL_JOURNEY_LIST: `/financialHealthCheck/getAllThemes`,
  GET_ALL_ACTIVE_JOURNEY_LIST: `/financialHealthCheck/getAllActiveThemes`,
  CHANGE_JOURNEY_STATUS: `/financialHealthCheck/changeThemeStatus`,
  DELETE_JOURNEY_RECORDS: `/financialHealthCheck/themeDelete`,
  GET_JOURNEY_DETAILS: `/financialHealthCheck/getThemeById`,
  HEALTH_CONSENT_DETAIL: "/financialHealthCheck/getConsentById",
  UPDATE_HEALTH_CONSENT: "/financialHealthCheck/consentUpdate",
  CREATE_JOURNEY_DETAILS: `/financialHealthCheck/createTheme`,
  UPDATE_JOURNEY_DETAILS: `/financialHealthCheck/themeUpdate`,
  CHANGE_JOURNEY_SEQUENCE: `/financialHealthCheck/themeChangeSequence`,
  JOURNEY_FILE_UPLOAD: `/financialHealthCheck/fileUpload`,
  JOURNEY_FILE_REMOVE: `/financialHealthCheck/fileDelete`,
  JOURNEY_ICON_UPLOAD: `/financialHealthCheck/iconUpload`,

  // htc-config >> responses
  GET_ALL_RESPONSE_LIST: `/financialHealthCheck/getAllThemeResponses`,
  GET_RESPONSE_DETAILS: `/financialHealthCheck/getThemeResponseById`,

  //htc-config >> Smart Engine Products
  GET_ALL_SMART_ENGINE_PRODUCTS: `/financialHealthCheck/getAllProductSequences`,
  GET_ALL_ACTIVE_THEMES: `/financialHealthCheck/getAllActiveThemes`,
  CREATE_PRODUCT_SEQUENCE: `/financialHealthCheck/createProductSequence`,
  GET_BY_ID_PRODUCT_SEQUENCE: `/financialHealthCheck/getProductSequenceById`,
  UPDATE_PRODUCT_SEQUENCE: `/financialHealthCheck/updateProductSequence`,
  DELETE_PRODUCT_SEQUENCE: `/financialHealthCheck/deleteProductSequence`,

  //Unsubscribe Email
  UNSUBSCRIBE_EMAIL: "/employee/unsubscribeEmail",
};
